var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    { staticClass: "pdf-schema-form-contributions-group" },
    [
      _c("validation-observer", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ invalid }) {
              return undefined
            },
          },
        ]),
      }),
      _vm.displayField("description")
        ? _c(
            "b-row",
            {
              staticClass: "sortable-field-container",
              attrs: { tabindex: _vm.schemaTabIndex(_vm.fieldIds.description) },
            },
            [
              _c(
                "b-col",
                { attrs: { xs: "12" } },
                [
                  _c("validation-provider", {
                    attrs: {
                      rules: _vm.validationRulesForFieldId(
                        _vm.fieldIds.description
                      ),
                      name: "Capital Contributions Description",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (validationContext) {
                            return [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder:
                                    "Capital Contributions Description",
                                  state: _vm.validationState(validationContext),
                                  disabled:
                                    _vm.isDisabledFieldComponent("description"),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                    return _vm.handleEnterPressed.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  focus: function ($event) {
                                    return _vm.setCurrentFieldById(
                                      _vm.fieldIds.description
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.formData[_vm.fieldIds.description],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      _vm.fieldIds.description,
                                      $$v
                                    )
                                  },
                                  expression: "formData[fieldIds.description]",
                                },
                              }),
                              _vm.errors("Capital Contributions Description")
                                ? _c("p", { staticClass: "invalid" }, [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(
                                          _vm.errors(
                                            "Capital Contributions Description"
                                          )
                                        ) +
                                        "\n        "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      988203050
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.displayField("amount")
        ? _c(
            "b-row",
            {
              staticClass: "sortable-field-container",
              attrs: { tabindex: _vm.schemaTabIndex(_vm.fieldIds.amount) },
            },
            [
              _c(
                "b-col",
                { attrs: { xs: "12" } },
                [
                  _c("validation-provider", {
                    attrs: {
                      rules: _vm.validationRulesForFieldId(_vm.fieldIds.amount),
                      name: "Capital Contributions Amount",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (validationContext) {
                            return [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: "Capital Contributions Amount",
                                  type: "number",
                                  min: "0",
                                  state: _vm.validationState(validationContext),
                                  disabled:
                                    _vm.isDisabledFieldComponent("amount"),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                    return _vm.handleEnterPressed.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  focus: function ($event) {
                                    return _vm.setCurrentFieldById(
                                      _vm.fieldIds.amount
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.formData[_vm.fieldIds.amount],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      _vm.fieldIds.amount,
                                      $$v
                                    )
                                  },
                                  expression: "formData[fieldIds.amount]",
                                },
                              }),
                              _vm.errors("Capital Contributions Amount")
                                ? _c("p", { staticClass: "invalid" }, [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(
                                          _vm.errors(
                                            "Capital Contributions Amount"
                                          )
                                        ) +
                                        "\n        "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4285554475
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }