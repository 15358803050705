<template>
  <b-form-group class="pdf-schema-form-contributions-group">
    <validation-observer ref="observer" v-slot="{ invalid }" />
    <!-- INITIAL CONTRIBUTIONS (Sum of Capital Contributions) ------------------------------------->
    <!-- NOT CURRENTLY USED, keeping in case we want to add it back later                        -->
    <!--    <b-row-->
    <!--      v-if="displayField('initial_contributions')"-->
    <!--      :tabindex="schemaTabIndex(fieldIds.initial_contributions)"-->
    <!--      class="sortable-field-container"-->
    <!--    >-->
    <!--      <b-col xs="12">-->
    <!--        <validation-provider-->
    <!--          v-slot="validationContext"-->
    <!--          :rules="validationRulesForFieldId(fieldIds.initial_contributions)"-->
    <!--          name="Initial Contributions"-->
    <!--        >-->
    <!--          <b-form-input-->
    <!--            v-model="formData[fieldIds.initial_contributions]"-->
    <!--            placeholder="Initial Contributions"-->
    <!--            :state="validationState(validationContext)"-->
    <!--            :disabled="isDisabledFieldComponent('initial_contributions')"-->
    <!--            @keydown.enter.prevent="handleEnterPressed"-->
    <!--            @focus="setCurrentFieldById(fieldIds.initial_contributions)"-->
    <!--          />-->
    <!--          <p v-if="errors('Initial Contributions')" class="invalid">-->
    <!--            {{ errors('Initial Contributions') }}-->
    <!--          </p>-->
    <!--        </validation-provider>-->
    <!--      </b-col>-->
    <!--    </b-row>-->


    <!-- Capital Contributions Description -------------------------------------------------------->
    <b-row
      v-if="displayField('description')"
      :tabindex="schemaTabIndex(fieldIds.description)"
      class="sortable-field-container"
    >
      <b-col xs="12">
        <validation-provider
          v-slot="validationContext"
          :rules="validationRulesForFieldId(fieldIds.description)"
          name="Capital Contributions Description"
        >
          <b-form-input
            v-model="formData[fieldIds.description]"
            placeholder="Capital Contributions Description"
            :state="validationState(validationContext)"
            :disabled="isDisabledFieldComponent('description')"
            @keydown.enter.prevent="handleEnterPressed"
            @focus="setCurrentFieldById(fieldIds.description)"
          />
          <p v-if="errors('Capital Contributions Description')" class="invalid">
            {{ errors('Capital Contributions Description') }}
          </p>
        </validation-provider>
      </b-col>
    </b-row>

    <!-- Capital Contributions Description -------------------------------------------------------->
    <b-row
      v-if="displayField('amount')"
      :tabindex="schemaTabIndex(fieldIds.amount)"
      class="sortable-field-container"
    >
      <b-col xs="12">
        <validation-provider
          v-slot="validationContext"
          :rules="validationRulesForFieldId(fieldIds.amount)"
          name="Capital Contributions Amount"
        >
          <b-form-input
            v-model="formData[fieldIds.amount]"
            placeholder="Capital Contributions Amount"
            type="number"
            min="0"
            :state="validationState(validationContext)"
            :disabled="isDisabledFieldComponent('amount')"
            @keydown.enter.prevent="handleEnterPressed"
            @focus="setCurrentFieldById(fieldIds.amount)"
          />
          <p v-if="errors('Capital Contributions Amount')" class="invalid">
            {{ errors('Capital Contributions Amount') }}
          </p>
        </validation-provider>
      </b-col>
    </b-row>
  </b-form-group>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PdfSchemaFormContributionsGroup',
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fieldIds: {},
    }
  },
  computed: {
    ...mapGetters('stagelineSchemaForm', [
      'formData',
      'mapFieldIdsToGroupedFieldsParts',
      'isDisabledField',
      'validationRulesForFieldId',
      'validationState',
      'resourceFields',
      'schemaTabIndex',
    ]),
    initialField() {
      return this.fields[0]
    },
    title() {
      return  this.initialField.title || this.initialField.original_field_label
    },
  },
  beforeMount() {
    this.fieldIds = this.mapFieldIdsToGroupedFieldsParts(this.fields)
  },
  methods: {
    ...mapActions('stagelineSchemaForm', [
      'setCurrentFieldById',
    ]),
    displayField(fieldName) {
      return Object.keys(this.fieldIds).includes(fieldName)
    },
    isDisabledFieldComponent(fieldName) {
      return this.isDisabledField(this.fields.find(f => f.id === this.fieldIds[fieldName]))
    },
    errors(fieldName) {
      return this.$refs.observer?.$_veeObserver?.errors && this.$refs.observer.$_veeObserver.errors[fieldName] ?
        this.$refs.observer.$_veeObserver.errors[fieldName].filter(error => !error.includes('required'))[0] :
        null
    },
    handleEnterPressed(e) {
      this.$emit('enterPressed', e.shiftKey ? 'prev' : 'next')
    },
  },
}
</script>


<style>
.pdf-schema-form-contributions-group {
  margin-bottom: 0;
}
.invalid {
  font-size: 80%;
  color: #dc3545;
  margin-bottom: 0;
}
</style>
